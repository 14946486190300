<template>
	<div class="GoodsInfoSSS">
		
		<div class="Top">
			<div class="Left">
				广告详情
			</div>
			<div class="Right">
				<span>
					<el-button type="primary" icon="el-icon-arrow-left" @click="$router.go(-1)">返回列表</el-button>
				</span>
			</div>
		</div>
		
		<div class="List">
			
			<div class="One">
				<li class="Sel" v-if="Ad.Id != 'add'">
					<span class="Sel">广告ID</span>
					<em class="Sel">
						{{Ad.Id}}
					</em>
				</li>
				
				<li class="Sel">
					<span class="Sel">*广告名</span>
					<em  class="Sel">
						<el-input v-model="Ad.Name" style="width: 400px;"></el-input>
					</em>
					<span>内部识别用</span>
				</li>
				
				<li class="Sel">
					<span class="Sel">*展示平台</span>
					<em  class="Sel">
						<el-select v-model="Ad.Platform">
							<el-option :label="'全平台'" :value="'all'"></el-option>
							<el-option :label="'电脑B端'" :value="'pc_b'"></el-option>
							<el-option :label="'电脑C端'" :value="'pc_c'"></el-option>
							<el-option :label="'移动C端'" :value="'mobi_c'"></el-option>
						</el-select>
					</em>
				</li>
				
				<li class="Sel">
					<span class="Sel">*分组特征</span>
					<em  class="Sel">
						<el-input v-model="Ad.SKey" style="width: 400px;"></el-input>
					</em>
					<span>分组特征相同时，可通过特征调取分组下多个广告，例如用于轮播组图</span>
				</li>
				
				
				<li class="Sel">
					<span class="Sel">展示图</span>
					<em  class="Sel">
						<el-upload
						  class="avatar-uploader" ref="uploader"
						  action="https://jsonplaceholder.typicode.com/posts/"
						  :show-file-list="false" :on-change="getFile" :limit="1" :auto-upload="false">
						  <img v-if="Ad.ShowThumb.length > 0" :src="Ad.ShowThumb" class="avatar">
						  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
					</em>
				</li>
				
				
				<li class="Sel">
					<span class="Sel">展示文字</span>
					<em  class="Sel">
						<el-input v-model="Ad.ShowTitle" style="width: 400px;"></el-input>
					</em>
				</li>
				
				<li class="Sel">
					<span class="Sel">展示子文字</span>
					<em  class="Sel">
						<el-input v-model="Ad.ShowSubTitle" style="width: 400px;"></el-input>
					</em>
				</li>
				
				<li class="Sel">
					<span class="Sel">背景颜色</span>
					<em  class="Sel">
						<el-color-picker v-model="Ad.BgColor" show-alpha></el-color-picker>
					</em>
				</li>
				
				<li class="Sel">
					<span class="Sel">*跳转点击</span>
					<em  class="Sel">
						<el-select v-model="Ad.JumpType">
							<el-option :label="'不跳转'" :value="''"></el-option>
							<el-option :label="'商品详情'" :value="'goodsId'"></el-option>
							<el-option :label="'资讯详情'" :value="'newsId'"></el-option>
							<el-option :label="'自定义内部地址'" :value="'uri'"></el-option>
							<el-option :label="'自定义全网网址'" :value="'url'"></el-option>
						</el-select>
					</em>
				</li>
				
				<li class="Sel" v-if="Ad.JumpType != ''">
					<span class="Sel">*跳转目标</span>
					<em  class="Sel">
						<el-input v-model="Ad.JumpTarget" style="width: 400px;"></el-input>
					</em>
				</li>

				<li class="Sel" v-if="Ad.Id != 'add'">
					<span class="Sel">状态</span>
					<em class="Sel">
						<el-select placeholder="设置状态" v-model="Ad.Status">
							<el-option label="展示中" :value="50"></el-option>
							<el-option label="不展示" :value="10"></el-option>
					    </el-select>
					</em>
				</li>
				
				<li class="Sel">
					<span class="Sel">排序权重</span>
					<em class="Sel">
						<el-input-number style="width: 100px;" :min="1" :max="9999" :controls="false" v-model="Ad.Order"></el-input-number>
					</em>
					<i>权重值越大排名越靠前</i>
				</li>
				
				<li v-if="Ad.Id != 'add'" class="Sel">
					<span class="Sel">创建时间</span>
					<em class="Sel">
						{{Ad.CreatedAt}}
					</em>
				</li>
				
				<li v-if="Ad.Id != 'add'" class="Sel">
					<span class="Sel">最后修改</span>
					<em class="Sel">
						{{Ad.UpdatedAt}}
					</em>
				</li>
				
				<li class="Sel">
					<span class="Sel"></span>
					<em class="Sel">
						<el-button @click="UpdateAd()" type="warning">{{Ad.Id == 'add' ? '添加':'修改'}}</el-button>
					</em>
				</li>
			</div>
		</div>
		
		
	</div>
</template>

<script>
	import Vue from 'vue'
	import {Select,Option,Dialog,Upload,InputNumber,ColorPicker} from 'element-ui'
	export default {
	  name: 'AdInfo',
	  props: {
	  },
	  data() {
	      return {
			  Ad:{
				  Id:'',
				  Name:'',
				  Platform:'all',
				  SKey:'',
				  ShowThumb:'',
				  ShowTitle:'',
				  ShowSubTitle:'',
				  JumpType:'',
				  JumpTarget:'',
				  Order:1,
				  Click:0,
				  BgColor:'rgba(255,255,255,1)',
				  CreatedAt:'',
				  UpdatedAt:'',
				  Status:50,
			  },
	      }
	  },
	  components: {
		'el-select':Select,
		'el-option':Option,
		'el-upload':Upload,
		'el-input-number':InputNumber,
		'el-color-picker':ColorPicker
	  },
	  created() {
	  	if(this.$route.params.Id == undefined){
			this.$message('缺少ID，页面无法工作')
			return
		}
		this.Ad.Id = this.$route.params.Id
		if(this.Ad.Id != 'add'){
			this.GetAd(this.$route.params.Id)
			return
		}
	  },
	  methods:{
		  getFile(file, fileList) {
			this.getBase64(file.raw).then(res => {
				this.uploadPic(res)
			});
		  },
		  getBase64(file) {
		        return new Promise(function(resolve, reject) {
		          let reader = new FileReader();
		          let imgResult = "";
		          reader.readAsDataURL(file);
		          reader.onload = function() {
		            imgResult = reader.result;
		          };
		          reader.onerror = function(error) {
		            reject(error);
		          };
		          reader.onloadend = function() {
		            resolve(imgResult);
		          };
		        });
		      },
		  			  uploadPic(_b64){
		  				  let data = {
		  				  	Service:'Goods',
		  				  	Class: 'File',
		  				  	Action: 'Base64Upload',
		  				  	Base64:_b64
		  				  }
		  				  this.$post(this.$store.getters.getApiHost,data)
		  				  .then((res) => {
		  				  	
		  				  	if(res.ErrorId != 0){
		  				  		this.$message(res.Msg)
		  				  		return
		  				  	}
		  				  	
							this.Ad.ShowThumb = res.Data.Url
							this.$refs.uploader.clearFiles()
		  				  	
		  				  })
		  				  .catch(function (response) {
							this.$message('网络请求错误')
		  				  })
		  			  },
		    GetAd(_id){
		  		let data = {Service:'Ad',Class: 'Ad',Action: 'Get',Id:_id,}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			if(res.ErrorId != 0){
		  				this.$message(res.Msg)
		  				return
		  			}
		  			this.Ad = res.Data
		  		})
		    },
			handleRemove(file, fileList) {
			    console.log(file, fileList);
			},
			handlePictureCardPreview(file) {
			},	  
			UpdateAd(){
				let data = {
					Service:'Ad',
					Class: 'Ad',
					Action: 'Update',
					Id:this.Ad.Id,
					Name:this.Ad.Name,
					Platform:this.Ad.Platform,
					SKey:this.Ad.SKey,
					ShowThumb:this.Ad.ShowThumb,
					ShowTitle:this.Ad.ShowTitle,
					ShowSubTitle:this.Ad.ShowSubTitle,
					JumpType:this.Ad.JumpType,
					BgColor:this.Ad.BgColor,
					JumpTarget:this.Ad.JumpTarget,
					Order:parseInt(this.Ad.Order),
				}
				if(this.Ad.Id == 'add'){
					data.Action = 'Add'
					data.Id = ''
				}
				this.$post(this.$store.getters.getApiHost,data)
				.then((res) => {
					this.$message(res.Msg)
					if(res.ErrorId == 0 && this.Ad.Id == 'add'){
						this.$Jump('/do/ad/list')
					}else if(res.ErrorId == 0){
						this.GetAd(this.Ad.Id)
					}
				})
			},
	  }
	}
</script>

<style>
.GoodsInfoSSS{
	background-color: #FFFFFF;
	margin: 20px;
	padding: 20px;
}
.GoodsInfoSSS .Top{
	display: flex;
	align-items: center;
}
.GoodsInfoSSS .Top .Left{
	
}
.GoodsInfoSSS .Top .Right{
	flex: 1;
	text-align: right;
	color: #999999;
}

.GoodsInfoSSS .List{
	margin-top: 20px;	
}
.GoodsInfoSSS .List .One{
	border: 1px solid rgba(0,0,0,0.04);
	padding: 20px;
	margin-bottom: 20px;
}
.GoodsInfoSSS .List .One li.Sel{
	display: flex;
	line-height: 20px;
	padding: 10px 0px;
	align-items: center;
}
.GoodsInfoSSS .List .One li.Sel span.Sel{
	width: 80px;
	color: rgba(0,0,0,0.3);
}
.GoodsInfoSSS .List .One li.Sel  em.Sel{
	flex: 1;
}
.GoodsInfoSSS .List .One li.Sel i.Sel{
	font-style: normal;
	color: rgba(0,0,0,0.2);
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 88px;
    height: 88px;
    line-height: 88px;
    text-align: center;
  }
  .avatar {
    width: 88px;
    height: 88px;
    display: block;
  }
  
.ql-editor{
        height:360px;
    }
</style>
